import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import apiService from "../services/apiService";
import { cashfree } from "./util";

function PaymentPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [sessionId, setSessionId] = useState("");
  const [initiatePayment, setInitiatePayment] = useState({});
  const [header, setHeader] = useState({});

  const order = {
    title: "Chemistry Video",
    contentType: "Video",
    tags: "Chemistry, 12th, math",
    teacher_name: "Ramesh",
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const initiatePayment = {
      content_id: params.get("content_id"),
      content_type: params.get("content_type"),
      store_product_id: params.get("store_product_id"),
      orderAmount: params.get("orderAmount"),
      status: params.get("status"),
    };
    setInitiatePayment(initiatePayment);
    const headerObj = {
      token: params.get("token"),
      key: params.get("key"),
    };
    setHeader(headerObj);
  }, [location.search, navigate]);

  const initiateCashFreePayment = async () => {
    try {
      const result = await apiService.post(
        "initiate.cashfree.order",
        initiatePayment,
        header
      );
      if (result?.data && result?.data?.payment_session_id) {
        setSessionId(result?.data?.payment_session_id);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoogleWalletPayment = async () => {
    try {
      const googleWallet = {
        content_id: initiatePayment?.content_id,
        content_type: initiatePayment?.content_type,
        store_product_id: initiatePayment?.store_product_id,
        status: initiatePayment?.status,
      };
      const result = await apiService.post(
        "initiate.order",
        googleWallet,
        header
      );
      if (result?.data && result?.data?.payment_session_id) {
        setSessionId(result?.data?.payment_session_id);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const doPayment = async () => {
    let checkoutOptions = {
      paymentSessionId: sessionId,
      redirectTarget: "_modal",
      return_url: `${process.env.REACT_APP_API}/api/get.cashfree.order/{order_id}`
    };
    cashfree.checkout(checkoutOptions).then((result) => {
      if (result.error) {
        // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
        console.log(
          "User has closed the popup or there is some payment error, Check for Payment Status"
        );
        console.log(result.error);
      }
      if (result.redirect) {
        // This will be true when the payment redirection page couldnt be opened in the same window
        // This is an exceptional case only when the page is opened inside an inAppBrowser
        // In this case the customer will be redirected to return url once payment is completed
        console.log("Payment will be redirected");
      }
      if (result.paymentDetails) {
        // This will be called whenever the payment is completed irrespective of transaction status
        console.log("Payment has been completed, Check for Payment Status");
        console.log(result.paymentDetails.paymentMessage);
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="order-details">
          <h2>Here are the order details</h2>
          <p>
            {order.title}, {order.contentType}, {order.tags},{" "}
            {order.teacher_name}
          </p>
        </div>
        <>
          {sessionId ? (
            <>
              <p>Click below to open the checkout page in a popup</p>
              <button
                type="submit"
                className="btn btn-primary"
                id="renderBtn"
                onClick={doPayment}
              >
                Pay Now
              </button>
            </>
          ) : (
            <>
              <div className="payment-option" onClick={initiateCashFreePayment}>
                <img
                  src="https://guruplus-data.s3.ap-south-1.amazonaws.com/uploads/temp/upi2.png"
                  alt="UPI"
                  className="payment-image"
                />
              </div>
              <div className="payment-option" onClick={handleGoogleWalletPayment}>
                <img
                  src="https://guruplus-data.s3.ap-south-1.amazonaws.com/uploads/temp/download.png"
                  alt="Google Wallet"
                  className="payment-image"
                />
              </div>
            </>
          )}
        </>
      </div>
    </>
  );
}
export default PaymentPage;
