
const BASE_URL =  `${process.env.REACT_APP_API}/api`;

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorData = await response.json();
    const errorMessage = errorData.message || 'Something went wrong';
    
    // Throw specific error types based on HTTP status code
    if (response.status === 401) {
      throw new UnauthorizedError(errorMessage);
    } else if (response.status === 403) {
      throw new ForbiddenError(errorMessage);
    } else {
      throw new Error(errorMessage);
    }
  }
  return response.json();
};

// Custom error classes
class UnauthorizedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'UnauthorizedError';
  }
}

class ForbiddenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ForbiddenError';
  }
}

const apiService = {
  get: async (endpoint, headerObj) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      headers: {
      },
    });

    return handleResponse(response);
  },

  post: async (endpoint, data, headerObj) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'POST',
      headers: {
        token: headerObj?.token,
        key: headerObj?.key,
        'Content-Type': 'application/json',
      },
      body:JSON.stringify(data),
    });

    return handleResponse(response);
  },

  delete: async (endpoint, data, headerObj) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'DELETE',
      headers: {
      },
      body: JSON.stringify(data)
    });

    return handleResponse(response);
  },

  put: async (endpoint, data, headerObj) => {
    const response = await fetch(`${BASE_URL}/${endpoint}`, {
      method: 'PUT',
      headers: {
      },
      body: data
    });

    return handleResponse(response);
  }
};

export default apiService;