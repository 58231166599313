import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Success from './component/Success';
import Failure from './component/Failure';
import Phonepe from './file/phonepe/Phonepe';
import PaymentPage from './component/paymentPage'

function App() {
  return (
    <BrowserRouter>
      <div className='main'>
        <Routes>
          {/* <Route exact path='/' element={<Phonepe />} /> */}
          <Route exact path='/success' element={<Success />} />
          <Route exact path='/failure' element={<Failure />} />
          <Route exact path='/payment' element={<PaymentPage />} />
          </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;


// http://localhost:8080/payment?session=session_ep-YgKMX3sqExdodHgkQLmG64DyR8QHVDrqg7pKfygypY6uvp390_51J5JtfrG4e4wiXKprv3YceLU-4Y0eLckjfSevrlOE1PGKln0hHSaTX
